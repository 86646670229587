import NextLink from 'next/link';

import cx from 'classnames';

import { type FragmentType } from '~/contentful/graphql';
import mellonLogo from '~/ui/assets/icons/logo-news.svg?url';
import { type AnalyticsModule, type CardEvent, Event } from '~/ui/components/analytics';
import { Button, ButtonSize, ButtonVariant } from '~/ui/components/button';
import { FullBleedCTAScope, FullBleedCTATrigger } from '~/ui/components/fullBleedCta';
import { Image } from '~/ui/modules/image';
import { getImageAspectRatio } from '~/ui/modules/image/getImageAspectRatio';
import { type Image_AssetFragment } from '~/ui/modules/image/query';
import truncateStyles from '~/ui/styles/truncate.module.scss';
import typographyStyles from '~/ui/styles/typography.module.scss';
import { getOrientation } from '~/ui/utils/aspectRatio';
import { Link } from '~/v1/components/link/link';

import styles from './base.module.scss';

/**
 * @property MINIMAL No logo or image. Used for related items carousels like "More annual reports" or "More grants to xyz"
 * @property COLLAPSED Uses logo or Mellon M if none is provided. Used in news carousels where there a mix of internal and external news
 * @property DEFAULT Includes image or logo, depending on what is provided
 * @property EMPHASIZED Large card that spans the whole page. Should not be rendered within a content grid
 */
export enum CardSize {
  /** See {@link CardSize} for description */
  MINIMAL = 'MINIMAL',
  /** See {@link CardSize} for description */
  COLLAPSED = 'COLLAPSED',
  /** See {@link CardSize} for description */
  DEFAULT = 'DEFAULT',
  /** See {@link CardSize} for description */
  EMPHASIZED = 'EMPHASIZED',
}

/* Props that can be passed to `BaseCard` but aren't used directly */
type InheritedCardProps = Omit<React.ComponentPropsWithoutRef<'article'>, 'children'>;

export interface BaseCardProps extends InheritedCardProps {
  eyebrow?: string;
  title: string;
  description?: string;
  cta: string;
  href: string;
  secondaryCta?: string;
  secondaryHref?: string;
  event?: Omit<CardEvent, 'cardTitle' | 'cardCTA'>;
  /** Truncate description to 3 lines */
  truncateDescription?: boolean;
  image?: FragmentType<typeof Image_AssetFragment>;
  logo?: FragmentType<typeof Image_AssetFragment>;
  /**
   * Determines what image/logo to render
   * @see CardSize
   */
  size: CardSize;
}

/* Most cards that render `BaseCard` allow `InheritedCardProps` and an `AnalyticsModule` to pass through */
export interface CommonCardProps extends InheritedCardProps {
  module?: AnalyticsModule;
}

export function BaseCard({
  eyebrow,
  title,
  description,
  cta,
  href,
  secondaryCta,
  secondaryHref,
  event,
  truncateDescription,
  image,
  logo,
  size,
  className,
  ...props
}: BaseCardProps) {
  let resolvedImage: typeof image = undefined;
  let resolvedLogo: typeof logo = undefined;

  switch (size) {
    case CardSize.EMPHASIZED:
    case CardSize.DEFAULT:
      if (image) {
        resolvedImage = image;
        break;
      }

    // fall through is intentional! If there is no image, we want to use the collapsed version of the card.
    case CardSize.COLLAPSED:
      resolvedLogo = logo || mellonLogo;
      break;

    case CardSize.MINIMAL:
      break;
  }

  const imageAspectRatio = image ? getImageAspectRatio(image) : undefined;
  // TODO: This is only calculated on the server. Should we use a hook to update the aspect ratio once it renders on the DOM?
  const imageOrientation = imageAspectRatio ? getOrientation(imageAspectRatio) : undefined;

  return (
    <FullBleedCTAScope>
      <article
        className={cx(
          styles.root,
          {
            [styles.emphasized]: size === CardSize.EMPHASIZED,
            [styles.portrait]: imageOrientation === 'portrait',
            [styles.hasSecondaryCta]: secondaryCta && secondaryHref,
          },
          className,
        )}
        {...props}
      >
        {resolvedImage && (
          <Image
            image={resolvedImage}
            className={styles.image}
            aspectRatio={{ portrait: '4/5', landscape: '3/2' }}
          />
        )}

        <div className={styles.content}>
          {resolvedLogo && (
            <div className={styles.logoContainer}>
              <Image image={resolvedLogo} />
            </div>
          )}

          <div className={styles.textContent}>
            {eyebrow && <div className={typographyStyles.bodySmall}>{eyebrow}</div>}
            <div className={typographyStyles.bodyLarge}>{title}</div>
            {description && (
              <div
                className={cx(typographyStyles.paragraph, {
                  [truncateStyles.truncate3]: truncateDescription,
                })}
              >
                {description}
              </div>
            )}
          </div>

          <div className={styles.ctaWrapper}>
            <FullBleedCTATrigger>
              <Link
                href={href}
                withIcon
                event={{
                  name: Event.CARD_CLICK,
                  data: {
                    cardTitle: title,
                    cardCTA: cta,
                    ...event,
                  },
                }}
              >
                {cta}
              </Link>
            </FullBleedCTATrigger>

            {secondaryCta && secondaryHref && (
              <Button
                size={ButtonSize.TINY}
                variant={ButtonVariant.SECONDARY_TRANSPARENT}
                render={<NextLink href={secondaryHref} />}
              >
                {secondaryCta}
              </Button>
            )}
          </div>
        </div>
      </article>
    </FullBleedCTAScope>
  );
}
