// This file is generated by scripts/generate-content-types.ts
/**
 * Contentful GraphQL `__typename`s
 *
 * Contentful uses this system to determine what to generate:
 * https://www.contentful.com/developers/docs/references/graphql/#/reference/schema-generation
 */
export enum Typename {
  ANNUAL_REPORT_PAGE = 'AnnualReport',
  ANNUAL_REPORT_PDF = 'OldAnnualReport',
  ARTICLE_EMPHASIZED = 'EmphasizedArticle',
  ARTICLE_PAGE = 'Article',
  AT_GLANCE_CARD = 'AtGlanceCard',
  CALL_FOR_CONCEPT = 'CallForConcept',
  CONTACT_PAGE = 'Contact',
  CONTACT_PERSON = 'ContactPerson',
  DEPARTMENT = 'Department',
  EVENT_UPCOMING = 'ExternalEvent',
  EVENTS_LANDING_PAGE = 'EventsPage',
  EXPERIENCE_BLOCK = 'ExperienceBlock',
  EXPLICIT_VALUE_FILTER = 'ExplicitValueFilter',
  EXTERNAL_LINK = 'QuickLink',
  FEATURE = 'Feature',
  FINANCIALS_PAGE = 'FinancialsPage',
  FOOTER = 'Footer',
  FREQUENTLY_ASKED_QUESTION = 'Faq',
  FREQUENTLY_ASKED_QUESTIONS_MODULE = 'FaqsModule',
  GRANT = 'Grant',
  GRANT_DATABASE_PAGE = 'GrantSearchFilters',
  GRANT_DETAILS_PAGE = 'GrantDetailsPage',
  GRANT_RESOURCES_PAGE = 'GrantResources',
  GRANT_STORY = 'GrantActivityArticle',
  GRANTMAKING_AREA = 'GrantmakingArea',
  GRANTMAKING_AREA_DETAILS_PAGE = 'GrantmakingAreaDetailsPage',
  GRANTMAKING_AREAS_PAGE = 'GrantmakingAreasPage',
  HISTORY_PAGE = 'HistoryPage',
  HISTORY_PERSON = 'HistoryPerson',
  HOME_PAGE = 'HomePage',
  IDEA_PAGE = 'Idea',
  INITIATIVE_QUOTE_CALL_OUT = 'InitiativeQuoteCallOut',
  INLINE_CARDS = 'InlineCards',
  INLINE_IMAGES = 'InlineImages',
  MAIN_NAVIGATION = 'MainNavigation',
  MISSION_PAGE = 'MissionPage',
  MMUF_PAGE = 'Mmuf',
  NEWS_LANDING_PAGE = 'NewsPage',
  NEWSLETTER_CARD = 'NewsletterCard',
  NEWSLETTER_CONFIRM_PAGE = 'NewsletterConfirmPage',
  NEWSLETTER_PAGE = 'NewsletterPage',
  ORGANIZATION = 'Organisation',
  PARALLAX_IMAGES = 'ParallaxImages',
  PEOPLE_PAGE = 'PeoplePage',
  PERSON_EXTERNAL = 'ExternalPerson',
  PERSON_STAFF = 'Person',
  PRESIDENTIAL_QUOTE_CALL_OUT = 'PresidentialQuoteCallOut',
  PRESS_ROOM_PAGE = 'PressRoom',
  PRIVACY_POLICY = 'PrivacyPolicy',
  QUICK_LINK = 'ConnectWithLink',
  QUICK_LINKS_MODULE = 'ConnectWith',
  QUOTE = 'Quote',
  RANGE_VALUE_FILTER = 'RangeValueFilter',
  ROOT_PAGE = 'RootPage',
  SEARCH_PAGE = 'SearchPage',
  SPOTLIGHT_SLIDESHOW = 'SpotlightSlideshow',
  SPOTLIGHT_VIDEO = 'SpotlightVideo',
  TAXONOMY = 'Taxonomy',
  TERMS_OF_USE_PAGE = 'TermsOfUse',
  THEME = 'Theme',
  TRUSTEE = 'Trustee',
  TRUSTEES_PAGE = 'TrusteesPage',
  VIDEO_NATIVE = 'VideoNative',
  VIDEO_YOU_TUBE = 'VideoYoutube',
  ZZZ_API_TASK_DO_NOT_DELETE = 'ZzzApiTask',
}
