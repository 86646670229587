import slugify from 'slugify';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';

import { GrantDetailsPageSlug_GrantFragment, GrantDetailsPageTitle_GrantFragment } from './query';

export function getGrantTitle(data: FragmentType<typeof GrantDetailsPageTitle_GrantFragment>) {
  const { id, referenceId, projectTitle } = getFragmentData(
    GrantDetailsPageTitle_GrantFragment,
    data,
  );
  return projectTitle?.replace(`[${id}] [${referenceId}] `, '').trim() ?? '';
}

export function getGrantSlug(data: FragmentType<typeof GrantDetailsPageSlug_GrantFragment>) {
  const grant = getFragmentData(GrantDetailsPageSlug_GrantFragment, data);
  const title = getGrantTitle(grant);
  return encodeURIComponent(`${slugify(title, { lower: true })}-${grant.id}`);
}

export function getGrantIdFromSlug(slug: string) {
  return slug.slice(slug.lastIndexOf('-') + 1);
}
