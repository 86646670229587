import camelcase from 'camelcase';
import { getFragmentData } from '~/contentful/graphql';
import { Typename } from '~/contentful/graphql/typename';
import { ArticleType } from '~/contentful/model/articleType';
import { ContentType } from '~/contentful/model/contentType';
import { getGrantSlug } from '~/ui/systems/grant';
import { invariant } from '~/utils/invariant';
import { Route } from '~/v1/constants/route';
import { InternalLink_EntryFragment } from './query';
/**
 * Constructs and returns internal links for different types of Contentful entries.
 *
 * For entries representing root pages like `Contact` or `EventsPage`, this function will
 * return a direct link to these pages.
 *
 * For repeatable entries like `Idea` or `GrantActivityArticle`, this function constructs
 * links including a `slug`, leading to individual instances of these types.
 *
 * @param data An instance of {@link InternalLink_EntryFragment}, representing a linkable entry from Contentful.
 * @throws {invariant.Violation} Throws an error if the entry type isn't linkable or the article subtype is unhandled.
 */ export function getInternalLinkFromFragment(data) {
    const entry = getFragmentData(InternalLink_EntryFragment, data);
    const args = [
        camelcase(entry.__typename)
    ];
    if (entry.__typename === Typename.ARTICLE_PAGE) {
        if (entry.externalNewsUrl) {
            return entry.externalNewsUrl;
        }
        var _entry_type;
        // @ts-expect-error this is to bypass GraphQL typing
        args.push((_entry_type = entry.type) !== null && _entry_type !== void 0 ? _entry_type : '');
    }
    // if
    if ([
        Typename.GRANT,
        Typename.GRANT_DETAILS_PAGE
    ].includes(entry.__typename)) {
        // @ts-expect-error args type expects `ContentType`
        args.push(getGrantSlug(entry));
    } else {
        // @ts-expect-error this is to bypass GraphQL typing
        args.push(entry.slug);
    }
    // @ts-expect-error this is to bypass GraphQL typing
    return getInternalLink(...args);
}
export function getInternalLink(contentType, slug, articleSlug) {
    switch(contentType){
        case ContentType.ANNUAL_REPORT_PAGE:
            return "".concat(Route.ANNUAL_REPORT, "/").concat(slug);
        case ContentType.ARTICLE_PAGE:
            const subType = slug;
            switch(subType){
                case ArticleType.GENERIC:
                    return "".concat(Route.ARTICLE, "/").concat(articleSlug);
                case ArticleType.GRANTEE:
                    return "".concat(Route.NEWS, "/").concat(articleSlug);
                case ArticleType.MELLON_IN_THE_NEWS:
                    return "".concat(Route.NEWS, "/").concat(articleSlug);
                case ArticleType.PAST_EVENT:
                    return "".concat(Route.EVENTS, "/").concat(articleSlug);
                case ArticleType.REPORT:
                    return "".concat(Route.REPORT, "/").concat(articleSlug);
                case ArticleType.VOICES:
                    return "".concat(Route.VOICES, "/").concat(articleSlug);
                default:
                    invariant(false, "Unknown article type: ".concat(subType));
            }
        case ContentType.CONTACT_PAGE:
            return Route.CONTACT;
        case ContentType.EVENTS_LANDING_PAGE:
            return Route.EVENTS;
        case ContentType.FEATURE:
            return "".concat(Route.FEATURE, "/").concat(slug);
        case ContentType.FINANCIALS_PAGE:
            return Route.FINANCIALS;
        case ContentType.GRANTMAKING_AREAS_PAGE:
            return Route.GRANT_MAKING_AREAS;
        case ContentType.GRANTMAKING_AREA_DETAILS_PAGE:
            return "".concat(Route.GRANT_MAKING_AREAS, "/").concat(slug);
        case ContentType.GRANT_DATABASE_PAGE:
            return Route.GRANT_SEARCH;
        case ContentType.GRANT_RESOURCES_PAGE:
            return Route.GRANT_RESOURCES;
        case ContentType.GRANT_STORY:
            return "".concat(Route.GRANT_STORY, "/").concat(slug);
        case ContentType.GRANT:
        case ContentType.GRANT_DETAILS_PAGE:
            return "".concat(Route.GRANT_DETAILS, "/").concat(slug);
        case ContentType.HISTORY_PAGE:
            return Route.HISTORY;
        case ContentType.HISTORY_PERSON:
            return "".concat(Route.HISTORY, "/").concat(slug);
        case ContentType.IDEA_PAGE:
            return "".concat(Route.IDEA, "/").concat(slug);
        case ContentType.MISSION_PAGE:
            return Route.MISSION;
        case ContentType.MMUF_PAGE:
            return Route.MMUF;
        case ContentType.NEWSLETTER_PAGE:
            return slug === 'newsletter' ? Route.NEWSLETTER : "".concat(Route.NEWSLETTER, "/").concat(slug);
        case ContentType.NEWS_LANDING_PAGE:
            return Route.NEWS;
        case ContentType.PEOPLE_PAGE:
            return Route.PEOPLE;
        case ContentType.PERSON_STAFF:
            return "".concat(Route.PEOPLE, "/").concat(slug);
        case ContentType.PRESS_ROOM_PAGE:
            return Route.PRESS_ROOM;
        case ContentType.PRIVACY_POLICY:
            return Route.PRIVACY_POLICY;
        case ContentType.ROOT_PAGE:
            invariant(slug, 'Root page must have a slug');
            return "/".concat(slug);
        case ContentType.TERMS_OF_USE_PAGE:
            return Route.TERMS_OF_USE;
        case ContentType.TRUSTEE:
        case ContentType.TRUSTEES_PAGE:
            return Route.TRUSTEES;
        default:
            invariant(false, "Unknown internal page type: ".concat(contentType));
    }
}
