import { graphql } from '~/contentful/graphql';

// Pages that do not require any data (e.g. root pages) don't need to be included here.
export const InternalLink_EntryFragment = graphql(`
  fragment InternalLink_EntryFragment on Entry {
    __typename
    ... on AnnualReport {
      slug
    }
    ... on Article {
      slug
      type
      externalNewsUrl
    }
    ... on Feature {
      slug
    }
    ... on GrantActivityArticle {
      slug
    }
    ... on GrantmakingAreaDetailsPage {
      slug
    }
    ... on Grant {
      ...GrantDetailsPageSlug_GrantFragment
    }
    ... on GrantDetailsPage {
      grant {
        ...GrantDetailsPageSlug_GrantFragment
      }
    }
    ... on HistoryPerson {
      slug
    }
    ... on Idea {
      slug
    }
    ... on NewsletterPage {
      slug
    }
    ... on Person {
      slug
    }
    ... on RootPage {
      slug
    }
  }
`);
