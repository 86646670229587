// This file is generated by scripts/generate-content-types.ts
/**
 * Contentful content type ids
 */
export enum ContentType {
  ANNUAL_REPORT_PAGE = 'annualReport',
  ANNUAL_REPORT_PDF = 'oldAnnualReport',
  ARTICLE_EMPHASIZED = 'emphasizedArticle',
  ARTICLE_PAGE = 'article',
  AT_GLANCE_CARD = 'atGlanceCard',
  CALL_FOR_CONCEPT = 'callForConcept',
  CONTACT_PAGE = 'contact',
  CONTACT_PERSON = 'contactPerson',
  DEPARTMENT = 'department',
  EVENT_UPCOMING = 'externalEvent',
  EVENTS_LANDING_PAGE = 'eventsPage',
  EXPERIENCE_BLOCK = 'experienceBlock',
  EXPLICIT_VALUE_FILTER = 'explicitValueFilter',
  EXTERNAL_LINK = 'quickLink',
  FEATURE = 'feature',
  FINANCIALS_PAGE = 'financialsPage',
  FOOTER = 'footer',
  FREQUENTLY_ASKED_QUESTION = 'faq',
  FREQUENTLY_ASKED_QUESTIONS_MODULE = 'faqsModule',
  GRANT = 'grant',
  GRANT_DATABASE_PAGE = 'grantSearchFilters',
  GRANT_DETAILS_PAGE = 'grantDetailsPage',
  GRANT_RESOURCES_PAGE = 'grantResources',
  GRANT_STORY = 'grantActivityArticle',
  GRANTMAKING_AREA = 'grantmakingArea',
  GRANTMAKING_AREA_DETAILS_PAGE = 'grantmakingAreaDetailsPage',
  GRANTMAKING_AREAS_PAGE = 'grantmakingAreasPage',
  HISTORY_PAGE = 'historyPage',
  HISTORY_PERSON = 'historyPerson',
  HOME_PAGE = 'homePage',
  IDEA_PAGE = 'idea',
  INITIATIVE_QUOTE_CALL_OUT = 'initiativeQuoteCallOut',
  INLINE_CARDS = 'inlineCards',
  INLINE_IMAGES = 'inlineImages',
  MAIN_NAVIGATION = 'mainNavigation',
  MISSION_PAGE = 'missionPage',
  MMUF_PAGE = 'mmuf',
  NEWS_LANDING_PAGE = 'newsPage',
  NEWSLETTER_CARD = 'newsletterCard',
  NEWSLETTER_CONFIRM_PAGE = 'newsletterConfirmPage',
  NEWSLETTER_PAGE = 'newsletterPage',
  ORGANIZATION = 'organisation',
  PARALLAX_IMAGES = 'parallaxImages',
  PEOPLE_PAGE = 'peoplePage',
  PERSON_EXTERNAL = 'externalPerson',
  PERSON_STAFF = 'person',
  PRESIDENTIAL_QUOTE_CALL_OUT = 'presidentialQuoteCallOut',
  PRESS_ROOM_PAGE = 'pressRoom',
  PRIVACY_POLICY = 'privacyPolicy',
  QUICK_LINK = 'connectWithLink',
  QUICK_LINKS_MODULE = 'connectWith',
  QUOTE = 'quote',
  RANGE_VALUE_FILTER = 'rangeValueFilter',
  ROOT_PAGE = 'rootPage',
  SEARCH_PAGE = 'searchPage',
  SPOTLIGHT_SLIDESHOW = 'spotlightSlideshow',
  SPOTLIGHT_VIDEO = 'spotlightVideo',
  TAXONOMY = 'taxonomy',
  TERMS_OF_USE_PAGE = 'termsOfUse',
  THEME = 'theme',
  TRUSTEE = 'trustee',
  TRUSTEES_PAGE = 'trusteesPage',
  VIDEO_NATIVE = 'videoNative',
  VIDEO_YOU_TUBE = 'videoYoutube',
  ZZZ_API_TASK_DO_NOT_DELETE = 'zzzApiTask',
}
