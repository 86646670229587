import { graphql } from '~/contentful/graphql';

export const GrantDetailsPageTitle_GrantFragment = graphql(`
  fragment GrantDetailsPageTitle_GrantFragment on Grant {
    id
    referenceId
    projectTitle
  }
`);

export const GrantDetailsPageSlug_GrantFragment = graphql(`
  fragment GrantDetailsPageSlug_GrantFragment on Grant {
    id
    ...GrantDetailsPageTitle_GrantFragment
  }
`);
